/* @import url('https://fonts.googleapis.com/css2?family=Courgette&family=Kalam:wght@300;400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap');

:root {
  --main-bg: #ff0095;
  --main-color: #d63189;
  --bg-black: #1e1e1e;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "M PLUS Rounded 1c", 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

html {
  scroll-behavior: smooth;

}

body {
  background-color: #ffffff;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}

input,
button {
  border: none;
  outline: none;
  background-color: none;
}

.heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.heading h1 {
  width: fit-content;
  text-align: left;
  text-decoration: underline;
  color: var(--bg-black);
  margin: 20px auto 30px !important;
}

.images-container {
  display: flex;
}

/*   ---Home Slider---   */

.homeSlide {
  display: flex;
  justify-content: center;
  margin: 0 !important;
  background-color: #f6f9fc;
  padding-bottom: 0 !important;
}

/*   ---Shop Pages---   */

.image-container {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.filter-bar-contianer .row {
  flex-wrap: wrap-reverse !important;
}

@media (max-width: 767px) {
  .filter-bar .row {
    gap: 20px;
  }
}

.not-found {
  margin: 40px auto 20px;
  width: fit-content;
}

/*   ---Cart Page---   */

.cart-items {
  background: #f6f9fc;
  height: auto;
}

.cart-items .cart-total {
  background-color: white;
  padding: 20px;
  height: 150px;
  margin-bottom: 30px;
  color: var(--main-bg);
}
.cart-title{ 
  color: var(--main-bg);
  font-weight: 800;
}

.cart-list {
  position: relative;
  background: white;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgb(3 0 70 / 40%) 0px 1.5px 3px;
}

.cart-list .image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-list .image-holder img {

  width: 100px;
}

.cart-items img {
  width: 100%;
  height: 100%;
  max-width: 300px;
  object-fit: contain;
}

@media (max-width: 767px) {
  .cart-items .container>.row {
    flex-wrap: wrap-reverse !important;
  }
}

.cart-items .cart-content {
  align-items: flex-end;
}

.cart-items .cart-details h3 {
  font-size: 1px;
  font-weight: 500;
  width: fit-content;
  text-align: center;
  margin-top: 20px;
}

.cart-items .cart-details h4 {
  font-size: 10px;
  font-weight: 400;
  margin-top: 50px;
  color: grey;
  width: fit-content;
}

.cart-items h4 span {
  color: #0f3460;
  margin-left: 20px;
  font-weight: 500;
}

.removeCart {
  background: none;
  font-size: 25px;
}

.cartControl {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  padding: 0 !important;
  /* margin: 1px 0 1px 0; */
  bottom: 0;
  right: 0px;
  /* position: absolute; */
}

.cartControl button {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  font-size: 20px;
}

.incCart {
  background: none;
  border: 1px solid rgb(3 0 71 / 9%);
  color: #0f3460;
}

.desCart {
  background: #f6f9fc;
}

.cart-total h4 {
  font-size: 15px;
  font-weight: 400;
}

.cart-total h3 {
  font-size: 20px;
  font-weight: 500;
  color: #0f3460;
}

.cart-total h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(3 0 71 / 9%);
  padding-bottom: 10px;
  color: #0f3460;
}

.no-items {
  color: #0f3460;
  font-size: 18px;
  margin-top: 30px;
  height: 130px;
}

button.delete {
  position: absolute;
  right: 0;
  top: 20px;
  width: max-content;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--bg-black);
}

button.delete ion-icon {
  font-size: 30px;
}

@media (max-width: 575px) {
  .cart-content .cart-details h3 {
    margin: 10px auto;
    font-size: 1rem;
  }

  .cart-content .cart-details h4 {
    margin: 10px auto;

  }
  .cart-content .cart-details h5 {
    margin: 10px auto;
    font-size: 0.8rem;
    width: 100%;
  }
  .cart-list .image-holder img {
    width: 100%;
  }


}

@media (max-width: 767px) {
  .product-listing{
    margin: -30px !important;
  }
  .cart-items .cart-total {
    margin-top: 30px;
  }
}