.whatsapp-button {
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 1000; /* Ensure the button is above other content */
    background-color:  transparent;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .whatsapp-button:hover {
    transform: scale(1.2);
    transition-duration: 300ms;
  }
  